.login-page {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

@media all and (max-width: 800px) {
  .login-page {
    flex-direction: column-reverse;
  }
}

.login-pane {
  flex: 1;
  display: flex;
  gap: 2em;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-pane {
  flex: 1;
  height: 100%;
}

.image-pane img {
  height: 100%;
  max-width: 100%;
  object-fit: cover;
}

.login-card {
  margin-top: 1em;
  max-width: 400px;
  width: 40%;
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
